.Hero_section{
    height: calc(100vh - 90px);
     position: relative;
    background-size: cover;
}
.Hero_section img{
    height: calc(100vh - 90px);
    object-fit:cover ;
}
.Hero_section .back_color{
width: 100%;
height: 100%;
 
background: linear-gradient(69deg, #000000,#7b5e3294,#ffffffa8);
position: absolute;
top: 0;
bottom: 0;
}
@media screen and (max-width:991px) {
    .Hero_section{
        min-height:900px ;
       
    }
    .Hero_section img{
        min-height:900px ;
       
    }
}
@media screen and (max-width:425px) {
    .Hero_section{
        min-height:1000px ;
       
    }
    .Hero_section img{
        min-height:1000px ;
       
    }
}

.w_h_Dynmic{
   max-width: 445px;
   max-height: 504px; 
    
   margin: auto;
}
 