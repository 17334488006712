 
.SimpleSliderCard  .card
{
    box-shadow: 4px 3px 14px 0px #d77804a6;
    margin: 4px;
    height: 260px !important;
    
}
 .BtnProductsCard .back_color
{
    position: absolute;
    background: #2e2e2e63;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    border-radius: 14px;
}
.SimpleSliderCard .icon_top{
    display: block;
    height: 80px;
    width: 80px;
    background: #ddd;
    border-radius: 50%;
    background: #2E2E2E;
}
.SimpleSliderCard .icon_top{
    font-size: 40px;
    color: #D77804;
}
.CardProduct{
    cursor: pointer;
}
.Img_div_catigory{
    height: 300px;
    background-image: url("../../asset/img/test_catgory_splash.svg");
    background-size: cover;
}
.btn_catigory_splash{
    margin-top: -40px;
}
@media screen and (max-width:767px) {
    .Img_div_catigory {
        height: 200px !important;
        
    }
    
}
.CategoryCartSplashPage.card{
 cursor: pointer;
 width: 90%;
 height: 380px;
}
.new_old.position-absolute {
    z-index: 20;
    right: 40px;
}
.color_div_catigory{
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 60px;
    font-weight: bold;
  
}
.CategoryCartSplashPage .main{
    color: #D77804;
    background-color: #ffffff7f;
    border: 3px solid;
    border-radius: 7px;

}
.CategoryCartSplashPage .light{
    color: #fff;
    background-color: #d77804a2;
    border-radius: 7px;


}
.SimpleSliderCard .card-title
{
font-size:    25px
}
@media screen and (max-width:768px) {
    .color_div_catigory{
     
        font-size: 40px;
        font-weight: bold;
        
    }
    .SimpleSliderCard .card-title
    {
    font-size:    21px
    }
    .SimpleSliderCard .card-text
    {
        font-size:11px
    }
    .SimpleSliderCard .icon_top {
   
        height: 60px;
        width: 60px;
       
    }
}

.img_slider {
    height: 300px;
}
@media screen and (max-width:488px) {
    .img_slider {
        height: 178px;
    }
}

.content_BtnProductsCard{
    max-height: 300px;
    overflow: hidden;
}
img.card-img-top.shadow.rounded-4 {
    object-fit: contain;
}
.BtnProductsCard img
{
    object-fit: cover;
    height: 700px;
}
.img_slider img{
    object-fit: contain !important;

}
.img_slider{
    border-radius: 20px;
}
.btn_link
{
    background: #D77804 !important;
    font-weight: bold !important;
    color: #fff !important;
    border-radius: 9px !important;
    height: 40px !important;
    font-size: 18px !important;
}
.CardProduct .price_cart{
    color: #D77804;
    font-weight: bold;
}
@media screen and (max-width:480px) {
    .CardProduct img.card-img-top{
          
            height: 163px;
       
    }
    .CardProduct p.card-title.fs-4 {
        font-size: 18px !important;
    }
    p.card-title.fs-6 {
        font-size: 12px !important;
    }
    .CardProduct .price_cart{
         font-size: 15px;
    }
    .CardProduct  .add_favorite{
        width: 25px;
        height: 25px;
        border-radius: 2px !important;
    }
}
 