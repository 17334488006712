.SingleSlider .slick-prev {
    z-index: 30;

    left: 22px;
}
.SingleSlider .slick-next {
    right: 22px;
    z-index: 30;
}
 
.slick-prev:before, .slick-next:before {
 
    font-size: 25px !important;
 
}
.slick-slide.slick-active.slick-current >div{
    box-shadow: none !important;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #d77804 !important;
}