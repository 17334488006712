.active>.page-link, .page-link.active {
    z-index: 3;
    color: #232323;
    background-color: #ffffff;
    border: 1px solid #ddd !important;
    border-radius: 7px;
}
li.page-item.disabled{
    border: none;
    color: black;
}
.page-item:not(:first-child) .page-link {
    margin-left: -1px;
    border: none;
    color: black !important;
}
.disabled>.page-link, .page-link.disabled{
    border: none;
    color: black !important;
}
.active>.page-link, .page-link.active{

    background: transparent !important;
}
 