.main_color{
  color: #D77804  !important;
}
.ligth-color{
  color: #fff;
}
.aut_form_with{
  width: 70%;
  margin: auto;
  padding: 40px;
}
@media screen and (max-width:720px) {
  .aut_form_with{
    width: 100%;
   padding: 10px !important;
  } 
}
a:hover{
  color:   #e58106 !important;
}
.color_red
{
 color: #EE0000 !important
}
h2{
 color: #2E2E2E
}
.title_2{
 font-size: 40px
 
}
.title_3{
 font-size: 36px;
 font-weight: bold;

}
.title_4{
  font-size: 30px;
 }
 .link_all_parts{
  font-size: 20px;
 }
 @media screen and (max-width:768px) {
  .title_3{
    font-size: 18px;
   }
   .link_all_parts{
    font-size: 14px;
   }
 }
 .Color_light{
  color: #fff;
 }
 .top_head_dedails{
  display: none !important;
}
.view_all_page{
  min-height: 60vh;
  overflow: hidden;
}
 @media   (max-width:991px) and (min-width:769px) {
  .view_all_page{
    min-height: 44vh;
  }
}
@media screen and (max-width:769px) {
  .view_all_page{
    min-height: 41vh;
  }
}
@media screen and (max-width:575px) {
  .view_all_page{
    min-height: 18vh;
  }
}

@media screen and (max-width:768px) {
  .top_head_dedails{
     
     display: flex !important;
  align-items: center;
  justify-content: space-between;
  }

}

