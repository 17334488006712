.find_parts{
    background-color: #0000001c;
    border-radius: 20px;
   width: 445px;
   height: 504px;
   padding: 30px;
}
@media screen and (max-width:720px) {
    .find_parts {
        width: 100% !important;
        padding: 30px !important;
    }
}
.find_parts .select__control.css-13cymwt-control  {
    height: 48px !important;
     
 
    box-shadow: 0px 0px 5px -2px #fff;
}
  .find_parts input{
    height: 34px !important;
    border-radius: 14px !important;


}