.requert_star{
   color: #FF0000
}
.checked_icon{
    right: 9px;
     top: 20%;
     z-index: 3;
}
.checked_icon_phone{
    right: 9px;
    top: 20%;
    z-index: 3;
}
.button_Code{
    background: #DD7608 !important;
  
    border-radius: 10px 0px 0px 10px !important;
    padding: 3px;
}
.error_color{
color: #FF0000;
}
.error_border{
border-color: #FF0000 !important;
}
.w_searsh
{
    width: 30%;
    
}
@media screen and (max-width:1070px) {
    .w_searsh
    {
        width: 25%;
        
    }   
}
@media screen  and (max-width:992px) {
    .w_searsh
{
    width: 75%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
}

.w_searsh input
{
    box-shadow: 0px 0px 15px 0px #fff;
}
.dirction_static{
    direction: ltr;
}