.CountAddREmove input{
 width: 60px;
 border: none;
 text-align: center;
}
.CountAddREmove button{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.CountAddREmove button:nth-of-type(1){
    background: transparent;
    border: 1px solid #D77804;
}
.CountAddREmove button:nth-of-type(2){
    color: #fff;
    background: #D77804;
    border: none;
    
}
.CountAddREmove button:nth-of-type(1):disabled{
    background: #abadaa  !important;
    border: 1px solid #abadaa !important;
    color: #fff;

  }