    .auto_hight{
        
        padding: 0px 3px;
        
    }
    @media screen and (max-width:480px){
        .SimilarParts .card-body.pb-0 {
            height: 60px;
           ;
        }
    }
   .SimilarParts .card-body.pb-0 {
        height: 130px;
        overflow: hidden;
    }