.img_slider img{
 object-fit: fill;
}
.DetailsProductTop .slick-prev:before,.DetailsProductTop .slick-next:before {
    font-family: 'slick';
    font-size: 30px;
    line-height: 1;
    opacity: .75;
    color: #D77804 !important ;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.DetailsProductTop .slick-slide{
    padding:10px
} 
 
.slick-slide.slick-active.slick-current >div
{   
     box-shadow: 0px 0px 5px 4px #dddddd73;
    border-radius: 17px;}
    .custom-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.custom-list li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  
}

.custom-list li:before {
  content:'';
  counter-increment: custom-counter;
  position: absolute;
  left: 0;
  top: 26%;
  background-color: transparent;
  border: 1px solid #707070;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  text-align: center;
  line-height: 18px;
}
@media screen and (max-width:767px) {
 .DetailsProductTop .img_slider{
  height: 263px;
}
.DetailsProductTop .img_slider{
  object-fit: contain;
}
.add_to_cart{
  display: none !important;
}
}

.DetailsProductTop .second_btn{
  width: 239px !important;
}  
.DetailsProductTop  .btn_addFavord{
  display: block;
 
  right: 16px;
  top: 13px;
  background: #fff;
  z-index: 20;
 
}
.DetailsProductTop  .btn_addFavord .add_favorite {
    width: 30px;
 
  height: 31px;
 
}
 
@media screen and (max-width:767px) {
 
.DetailsProductTop  .btn_addFavord{
    display: none;
  }
}