svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-completed.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root{
    color: #DD7608 !important;
}
/* TrackingOrder.css */

.MainContainer {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 16px;
  }
  
  .StepContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    position: relative;
  }
  
  .StepContainer:before {
    content: "";
    position: absolute;
    background: #f3e7f3;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  
  .StepContainer:after {
    content: "";
    position: absolute;
    background: #4a154b;
    height: 4px;
     
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
  
  .StepWrapper {
    position: relative;
    z-index: 1;
  }
  
  .StepStyle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 3px solid #f3e7f3;
    transition: 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .StepStyle[step="completed"] {
    border-color: #4a154b;
  }
  
  .StepCount {
    font-size: 19px;
    color: #f3e7f3;
  }
 
  
  .StepsLabelContainer {
    position: absolute;
    top: 66px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .StepLabel {
    font-size: 19px;
    color: #4a154b;
  }
  
  
  .ButtonsContainer {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px;
    margin-top: 100px;
  }
  
  .ButtonStyle {
    border-radius: 4px;
    border: 0;
    background: #4a154b;
    color: #ffffff;
    cursor: pointer;
    padding: 8px;
    width: 90px;
  }
  
  .ButtonStyle:active {
    transform: scale(0.98);
  }
  
  .ButtonStyle:disabled {
    background: #f3e7f3;
    color: #000000;
    cursor: not-allowed;
  }
  
  .CheckMark {
    font-size: 26px;
    font-weight: 600;
    color: #4a154b;
    -ms-transform: scaleX(-1) rotate(-46deg);
    -webkit-transform: scaleX(-1) rotate(-46deg);
    transform: scaleX(-1) rotate(-46deg);
  }
  .css-1hv8oq8-MuiStepLabel-label.Mui-active {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold !important;
    font-size: 18px;
}
.css-1pyhj92-MuiStepConnector-root {
 
    left: calc(-58% + 20px);
   }
