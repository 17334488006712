.Navbar_Color{
    background:#2E2E2E
}
.Navbar_Color .active{
color: #D77804 !important;
font-weight: bolder;
}
.Navbar_Color .nav-link{
    color: #fff ;
} 
.Navbar_Color .nav-link:hover,.Navbar_Color .nav-link:active{
    color: #D77804 !important;
}
 
.Navbar_Color .url_search .input_search
{
    height: 84px;
    background-color: #fff;
    border-radius: 20px 0px 0px;
    
}
.Navbar_Color .container-fluid{
  padding-right: 0px;
 }
 .Navbar_Color .url_search .input_search .row
 {
    padding: 20px 6px 12px;
    box-shadow: 24px 7px 13px 0px #dddddd80;
    border-radius: 0px 0px 30px;
 }
 .icon_navbar{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    width: 43px;
    flex-direction: column;
 }
 .icon_navbar svg{
    width: 32px;
}
@media screen and (max-width:991px) {
    .hidden_in_moble{
        display: none  !important;
    }
    .input_hidden{
        display: none;
    }
    .input_search {
        border-radius: 0px 0px 20px 20px !important;
        z-index: 3;
        position: sticky;
    }
    .icon_navbar svg{
        width: 30px;
    }
    .Navbar_Color .url_search {
        height: auto !important;
    }
    .Navbar_Color .container-fluid{
        padding-left: 0px;
       }
       .Navbar_Color .url_search .input_search{
        border-radius: 0px;
       }

       .Navbar_Color .url_search .input_search .row{
        border-radius: 20px;
       
       }
}
 .btn_searsh{
   
    background-color: transparent;
   
 }
 .img_user{
    border: 1px solid #fff;
 }
 @media screen and (max-width:420px) {
 .img_logo
 {
 width:   144px !important
 }   
 }
 @media (min-width: 992px){
.navbar-expand-lg .navbar-toggler {
    display: none !important;
}

 }
 .navbar-brand{
    direction: ltr;
 }
 /* div#navbarNavDropdown.show{
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
 } */
 .searsh_div{
    display: none;
 }
 @media screen and (max-width:991px) {
    .hidden_in_web{
        display: none;
    }
    .searsh_div{
        display: block;
        box-shadow: 0px 3px 16px 0px #ddd;
        border-radius: 0px 0px 20px 20px;
        margin-bottom: 21px;
    }
 }
 nav .lang_flag
{ min-width: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px;}
    .nav_usd_new .btn-check:checked+.btn,.nav_usd_new .btn.active,.nav_usd_new .btn.show,.nav_usd_new .btn:first-child:active,.nav_usd_new :not(.btn-check)+.btn:active {
        color: #Fff;
        
        background-color: #D77804;
        border-color: #ffffff;
    }
    .nav_usd_new .btn {
        color: #000;
    }
    .nav_usd_new .btn:hover {
        color: #000 !important
    }
    .li_Catigory_Select{
        padding: 2px;
        cursor: pointer;
        margin: 3px;
        text-align: center;
    }
   