.AsideCategories button.accordion-button {
    background: #fff !important;
    border: none !important;
    padding: 0;
    box-shadow:none
}
.AsideCategories  .accordion-item {
    border: none !important;
}
.accordion-button:not(.collapsed){
    color: #4B4B4B;
}
.AsideCategories {
    box-shadow: 5px 3px 5px 3px #dddddd30;
    border-radius: 0px 0px 20px 0px;
}
.subCategories .accordion-button::after  {
  
    content: "+"  ;
    flex-shrink: 0; 
    margin-left: auto;
 
       background-image: none !important;  
      transition: unset !important;
      transform: none !important;
  }
 .AsideCategories a{
    text-decoration: none;
    color: inherit;
 }
 .title-part{
    font-size: 23px;
 }
 .btn_hover:hover{
    
    box-shadow: 1px 1px 10px #ddd;
  
 }
 .btn_hover:active{
  border: 1px solid #d77804 !important;
 }
 .active_border{
 box-sizing: border-box;
  border: 1px solid #d77804 !important;

 }
 
  
  input[type='range'] {
    box-sizing: border-box;
    appearance: none;
   width: 100%;
    margin: 0;
    padding: 0 2px;
    /* Add some L/R padding to ensure box shadow of handle is shown */
    overflow: hidden;
    border: 0;
    border-radius: 1px;
    outline: none;
    background: linear-gradient(grey, grey) no-repeat center;
    /* Use a linear gradient to generate only the 2px height background */
    background-size: 100% 2px;
    pointer-events: none;
  }
  
  input[type='range']:active,
  input[type='range']:focus {
    outline: none;
  }
  
  input[type='range']::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 28px;
    background-color: #D77804 ;
    position: relative;
    margin: 5px 0;
   
    cursor: pointer;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
  }
  
  input[type='range']::-webkit-slider-thumb::before {
    content: ' ';
    display: block;
    position: absolute;
    top: 13px;
    left: 100%;
    width: 2000px;
    height: 2px;
  }
  
  .multi-range {
    position: relative;
    height: 30px;
  }
  
  .multi-range input[type='range'] {
    position: absolute;
  }
  
  .multi-range input[type='range']:nth-child(1)::-webkit-slider-thumb::before {
    background-color: red;
  }
  
  .multi-range input[type='range']:nth-child(2) {
    background: none;
  }
  
  .multi-range input[type='range']:nth-child(2)::-webkit-slider-thumb::before {
    background-color: grey;
  }
  .form-check-input:checked {
    background-color: #d77804 !important;
    border-color: #d77804 !important;
}