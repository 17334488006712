.SplashPage{
    background-image: url("https://testecommerce.vodoglobal.com/data/banner/WhatsApp Image 2023-05-18 at 09.05.25.jpg");
    background-size: cover;
}
.bac_color_SplashPage{
    background-color: #2e2e2ea1;
    height:91vh;
}

.Slider_SplashPage{
   
        max-width: 1024px;
    
}
.spinner_splash
{
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffffa3;
    align-items: center;
    justify-content: center;
}
.SplashPage .slick-prev, .SplashPage .slick-next{
    display: none !important;
}