.PaymentMethodSelect .btn-check:checked+.btn,.PaymentMethodSelect .btn.active,.PaymentMethodSelect .btn.show,.PaymentMethodSelect .btn:first-child:active,.PaymentMethodSelect :not(.btn-check)+.btn:active {
    color: #Fff;
    
    background-color: #D77804;
    border-color: #ffffff;
}
.PaymentMethodSelect .btn {
    color: #000;
}
.PaymentMethodSelect .btn:hover {
    color: #000 !important
}
.div_Delivery_price{
    width: 180px;
}
.loddingPAge{
    height: 100vh;
    position: fixed;
   right: 0;
   left: 0;
   top: 0;
    background-color: #6a656589;
    bottom: 0;
    z-index: 1000;
    display: flex
    
}