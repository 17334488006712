.second_btn{
    border:2px solid #D77804 !important;
    color: #D77804  !important;
    background-color: #ffff  !important;
    font-size: 22px !important;
    font-weight: bold !important;
  }
  .main_btn{
    border: #D77804 !important;
    color: #ffff  !important;
    background-color: #D77804  !important;
    font-size: 32px !important;
    font-weight: bold !important;
     height: 56px;
    width: 239px;
    border-radius: 20px;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    line-height: 0px !important;
  }
  @media screen and (max-width:992px) {
    .main_btn{
      font-size: 18px !important;
    }
  }
  .main_btn:hover{
    color: #fff !important;
  }
  .main_btn:disabled{
    background-color: #abadaa  !important;

  }
  /* .second_btn:disabled{
    background-color: #abadaa  !important;
    color: #fff !important;
    border-color: #D37604   !important;

  } */
  .add_btn{
    border: #D77804 !important;
    color: #ffff  !important;
    background-color: #D37604  !important;
    font-size: 16px !important;
    font-weight: bold !important;
    padding: 5px 20px !important;
  }
  .add_favorite{
    background: transparent;
    border: 1.5px solid  #868686 !important;
    width: 50px;
    display: flex;
    height: 43px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 25px !important;
    border-radius: 3px;
  }
  .AsideBorderBtn{
    border-left:2px solid #70707097 !important;
    color: #D77804  !important;
    background-color: #ffff  !important;
    font-size: 22px !important;
    font-weight: bold !important;
    border-radius: 0px !important;
  }