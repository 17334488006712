.gear-clock-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 300px;
    margin: auto;
  }
  
  .gear {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    animation: rotate 4s linear infinite;
  }
  
   
  
  .gear-small {
    left: 0px;
  }
  
  .gear-medium {
    right: 12px;
    top: 131px;
}
   
  
  .gear-large {
    top: 227px;
    right: 31px;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  