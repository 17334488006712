.CartShoping img{    height: 450px !important;
    object-fit: contain;
    box-shadow: 0px 0px 4px 1px #ddd;
    border-radius: 18px;}
    .CartShoping   .btn_addFavord{
            display: none;
    }
    @media screen and (max-width:767px) {
        .CartShoping .AsideBorderBtn.hidden_web{
            display: none;
        }
        .CartShoping .AsideBorderBtn{
            border: none !important;
            
        }
        .CartShoping .div_Button{
            justify-content: flex-end
        }
      .CartShoping  .btn_addFavord{
            display: block;
            right: 14px;
            top: 7px;
            background: #fff;
        }
    }