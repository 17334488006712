.FooterSplashPage h3 {
color: #fff;
}
.h2_color{
    color: #fff;

}
.FooterSplashPage .d-flex.align-items-center.my-2::before {
    content: "";
    background: #ce7810;
    width: 3px;
    height: 128%;
}
@media screen and  (max-width:768px) {
    .FooterSplashPage h3 {
        font-size:12px
        }
        .h2_color{
            font-size: 18px;
        
        }
       
}