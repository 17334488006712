@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600&display=swap");

:root {
  --melorraOrange: #DD7608;
  --blueShade1: #2c3e50;
  --blueShade2: #34495e;
  --boxShadowLight1: 0 2rem 5rem rgba(0, 0, 0, 0.06);
  --boxShadwoDark1: 0 2rem 6rem rgba(0, 0, 0, 0.3);
}

 
.order-track {
  margin-top: 2rem;
  padding: 0 1rem;
 
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
}
.order-track-step {
  display: flex;
  height: 7rem;
}
.order-track-step:last-child {
  overflow: hidden;
  height: 4rem;
}
 
.order-track-status {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  position: relative;
}
.order-track-step.active .order-track-status-dot  {
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: var(--melorraOrange);
  border: 2px solid var(--melorraOrange);

}
.order-track-status-dot {
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: #dddddd;
    border: 2px solid #dddddd;
}
.order-track-status-line {
    display: block;
    margin: 0 auto;
    width: 2px;
    height: 7rem;
    border: 2px solid #dddddd;
    border-style: dashed;
}
.order-track-step.active .order-track-status-line  {
  
  display: block;
  margin: 0 auto;
  width: 2px;
  height: 7rem;
  border: 2px solid var(--melorraOrange);
  border-style: dashed;
}
.order-track-text-stat {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 3px;
  color: #838383;

}
.order-track-text-sub {
  font-size: 1rem;
  font-weight: 300;
  color: #838383;
}
.order-track-step.active .order-track-text-stat {
  
    color: #838383;
  
  }
  .order-track-step.active .order-track-text-sub {
   
    color: #838383;
  }
.order-track {
  transition: all 0.3s height 0.3s;
  transform-origin: top center;
}

.order-track-step.active .order-track-status-line{
    display: none;
}