footer{
  background: #2E2E2E;
  color: #D77804 !important;
  line-height: 1.7;
}
footer a {
  color: #D77804 !important;
  text-decoration: none;
}
footer hr{
    border: 1px solid !important;
}
 .border_main_color{
  border:2px solid #D77804 !important;
 }
 .title_mbel{
  display: none;
 }
 @media screen and (max-width:575px) {
  .title_mbel{
    display: block;
   }
   .title_web{
   display: none;
   }
 }